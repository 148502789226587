<template>
  <div class="training-detail">
    <v-container fluid>
      <header v-if="trainingData" class="header white--text pb-3" mt-2>
        <div class="header__back-text">
          <v-btn
            class="white--text font-weight-bold"
            text
            :to="`/events/${$route.params.eventId}/trainings`"
          >
            <v-icon color="white" size="30"> mdi-chevron-left</v-icon>
            <span style="font-size: 20px"> {{ $t('globals.back') }} </span>
          </v-btn>
        </div>
        <v-btn class="mdi-arrow-left" text to="../">
          <v-icon color="white" size="30">mdi-arrow-left</v-icon>
        </v-btn>
        {{ trainingData.name }}
      </header>
      <v-row class="training-detail__body">
        <v-col class="training-detail__body__section" cols="12" order="2" order-md="1" sm="4">
          <v-tabs v-model="tab">
            <v-tab :key="itemTab[0]">{{ itemTab[0] }}</v-tab>
            <v-tab :key="itemTab[1]">{{ itemTab[1] }}</v-tab>
            <v-tab :key="itemTab[2]" class="hidden-lg-and-up">{{ itemTab[2] }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :key="itemTab[0]">
              <TrainingsSection :trainingsSections="trainingsSections" />
            </v-tab-item>
            <v-tab-item :key="itemTab[1]">
              <NotesSection />
            </v-tab-item>
            <v-tab-item :key="itemTab[2]">
              <FormerMobileSection />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          class="px-0 mt-n7 training-detail__body__content order-1"
          cols="12"
          order="1"
          order-md="2"
          sm="8"
        >
          <div class="header--title white--text">
            <div class="process">
              {{ $t('admin.training.trainingList.table.headers.progress') }}
              <span class="process--data font-weight-bold">{{ currentTrainingProgress }}%</span>
            </div>
            <div class="um font-weight-bold">um</div>
            <div v-if="false" class="process">Final score <span>--%</span></div>
          </div>
          <div class="training-detail__body__content__wrap white--text">
            <TrainingsLesson
              :trainingsSections="trainingsSections"
              :lesson="lessonItem"
              :lessonList="lessonList"
              :navigationAllowed="navigationAllowed"
              @set-isInProgressCurrentLesson="setIsInProgressCurrentLesson"
              @update-navigationAllowed="updateNavigationAllowed"
            />
            <div
              v-for="(trainer, idx) in listTrainer"
              :key="`trainer-${idx}`"
              class="training-detail__body__content__wrap__description white--text mt-4"
            >
              <div v-if="trainer" class="formateur--section">
                <p>{{ $t('admin.training.trainingList.table.headers.trainer') }}</p>
                <div class="d-flex">
                  <div class="section__avatar d-flex align-center mr-8">
                    <v-avatar color="gray" size="80">
                      <img v-if="trainer.avatar" :src="trainer.avatar" alt="avatar" />
                      <v-icon v-else color="black" size="60">mdi-account-circle</v-icon>
                    </v-avatar>
                    <div class="section__avatar__info ml-2">
                      <div class="info__name font-weight-bold">
                        {{ `${trainer.firstName} ${trainer.lastName}` }}
                      </div>
                    </div>
                  </div>
                  <div class="section__action" style="opacity: 0">
                    <div class="d-flex align-center font-weight-bold">
                      <img class="mr-1" src="~@assets/icons/MessageWhite.svg" width="20" />
                      {{ $t('trainings.details.message') }}
                    </div>
                    <div class="d-flex align-center font-weight-bold">
                      <img class="mr-1" src="~@assets/icons/Shedule.svg" width="20" />
                      {{ $t('trainings.details.schedule') }}
                    </div>
                    <div
                      class="d-flex align-center font-weight-bold section__action__text-video-call"
                    >
                      <img class="mr-1" src="~@assets/icons/VideoCall.svg" width="20" />
                      {{ $t('trainings.details.videoCall') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="description--section ml-8">
                <p>{{ $t('admin.training.admin.section.description') }}</p>
                <div class="description--section__content">
                  <div class="black--text ql-editor" v-html="trainer.about"></div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      content-class="v-dialog--overlay"
      v-model="shouldAskUserInformation"
      :persistent="true"
      width="500"
    >
      <v-form @submit.prevent="saveCustomFields">
        <v-card>
          <v-card-title>
            <span class="headline">{{
              $t('admin.training.certification.customUserField.userInformationRequired')
            }}</span>
          </v-card-title>

          <v-card-text class="form">
            <v-row v-for="field in getUserFields" :key="field.customField">
              <v-col cols="12">
                <v-text-field
                  v-model="customUserFields[field.customField]"
                  :label="field.name"
                  :persistent-placeholder="true"
                  placeholder="-"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="form-row justify-center mt-4">
                  <v-btn class="mr-2" color="primary" outlined @click.native="saveCustomFields">
                    {{ $t('globals.save') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <dialog-confirmation
      :id="`${this.lessonId}-dialog-confirm`"
      :visible="showConfirmation"
      :title="$t('trainings.redirectConfirmationDialog.title')"
      :content="$t('trainings.redirectConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </div>
</template>
<script>
import FormerMobileSection from '@/components/trainings/trainings-former/FormerMobileSection.vue';
import TrainingsLesson from '@/components/trainings/trainings-lesson/TrainingsLesson.vue';
import NotesSection from '@/components/trainings/trainings-note/NotesSection.vue';
import TrainingsSection from '@/components/trainings/trainings-section/TrainingsSection.vue';
import DialogConfirmation from '@/views/DialogConfirmation.vue';

import {
  GET_ALL_TRAININGS_SECTION_ADMIN,
  GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import {
  CLEAR_ALL_TRAINING,
  DELETE_TRAINING,
  GET_ALL_TRAININGS_ADMIN,
  GET_ALL_TRAININGS_ADMIN_PROGRESS,
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import {
  SET_CUSTOM_USER_FIELDS,
  TRAINING_MODULE,
} from '@/stores/umanize-app/actions/training/training.actions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TrainingsDetail',
  components: {
    TrainingsSection,
    NotesSection,
    TrainingsLesson,
    FormerMobileSection,
    DialogConfirmation,
  },
  data: () => ({
    lessonItem: {},
    lessonList: [],
    tab: 'Training',
    itemTab: ['Training', 'Notes', 'Former'],
    lessonId: '',
    sectionId: '',
    listTrainer: [],
    customUserFields: {},
    showConfirmation: false,
    redirect: undefined,
    isInProgressCurrentLesson: false,
    navigationAllowed: true,
    trainingsSections: [],
  }),
  computed: {
    ...mapGetters(TRAINING_MODULE, ['trainingState']),
    ...mapGetters(TRAINING_ADMIN_MODULE, [
      'allTrainingState',
      'trainingStateAreLoading',
      'allTrainingData',
      'trainingData',
      'trainingProgress',
      'trainingProgressLoading',
    ]),
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, [
      'allPublicTrainingSectionData',
      'trainingSectionStateAreLoading',
      'currentTrainingProgress',
    ]),
    currentProgress() {
      let totalLesson = 0;
      let finishedLesson = 0;
      this.trainingsSections.forEach((section) => {
        section.lessons.forEach((lesson) => {
          totalLesson++;
          if (lesson.isDone) {
            finishedLesson++;
          }
        });
      });
      return Math.round((finishedLesson * 100) / totalLesson);
    },
    shouldAskUserInformation() {
      if ((this.trainingData.certification?.customUserFields?.length || 0) > 0) {
        for (let i = 0; i < this.trainingData.certification.customUserFields.length; i++) {
          const customField = this.trainingData.certification.customUserFields[i];
          // this.initCustomUserField(customField.customField);
          if (
            !this.trainingProgress.certification?.customFields ||
            this.trainingProgress.certification.customFields.filter(
              (field) => field.customField === customField.customField && field.value !== '',
            ).length === 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
    getUserFields() {
      return this.trainingData.certification?.customUserFields || [];
    },
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [
      GET_ALL_TRAININGS_ADMIN,
      DELETE_TRAINING,
      CLEAR_ALL_TRAINING,
      GET_TRAINING,
      GET_ALL_TRAININGS_ADMIN_PROGRESS,
    ]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [
      GET_ALL_TRAININGS_SECTION_ADMIN,
      GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON,
    ]),
    initTrainingsSections(allPublicTrainingSectionData) {
      const AUTHORIZE_FIRST_LESSON = true;
      const getTheLastItem = (list) => list.slice(-1)[0];
      this.trainingsSections = allPublicTrainingSectionData.map(
        (trainingSection, indexTrainingSection) => ({
          ...trainingSection,
          lessons: trainingSection?.lessons?.map((lesson, index) => {
            let authorize;
            if (index === 0 && indexTrainingSection === 0) {
              authorize = AUTHORIZE_FIRST_LESSON;
            } else if (index === 0) {
              authorize = getTheLastItem(
                allPublicTrainingSectionData[indexTrainingSection - 1].lessons,
              ).isDone;
            } else {
              authorize = trainingSection.lessons[index - 1].isDone;
            }

            return {
              ...lesson,
              authorize: authorize ?? false,
            };
          }),
        }),
      );
    },
    setIsInProgressCurrentLesson(inProgress) {
      this.isInProgressCurrentLesson = inProgress;
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
      this.updateNavigationAllowed(false);
    },
    confirmationDialogConfirm() {
      this.showConfirmation = false;

      if (this.redirect) {
        this.setIsInProgressCurrentLesson(false);
        this.updateNavigationAllowed(true);
        this.redirect();
      }
    },
    updateNavigationAllowed(allowed) {
      this.navigationAllowed = allowed;
    },
    ...mapActions(TRAINING_MODULE, [SET_CUSTOM_USER_FIELDS]),
    initCustomUserField(fieldName) {
      if (!this.trainingProgress?.certification?.customFields) {
        this.customUserFields[fieldName] = '';
      } else {
        this.trainingProgress.certification.customFields.forEach((field) => {
          if (field.customField === fieldName) {
            this.customUserFields[fieldName] = field.value;
          }
        });
      }
    },
    getUserFieldValue(field) {
      return this.customUserFields[field] || '';
    },
    async saveCustomFields() {
      const { eventId, trainingId } = this.$route.params;
      await this[SET_CUSTOM_USER_FIELDS]({ eventId, trainingId, data: this.customUserFields });
      return false;
    },
  },
  watch: {
    trainingSectionStateAreLoading(loading) {
      if (!loading) {
        this.initTrainingsSections(this.allPublicTrainingSectionData);
      }
    },
  },
  async mounted() {
    const { eventId, trainingId } = this.$route.params;
    await Promise.all([
      this[GET_TRAINING]({
        eventId,
        trainingId,
      }),
      this[GET_ALL_TRAININGS_ADMIN_PROGRESS]({
        eventId,
        trainingId,
      }),
      await this[GET_ALL_TRAININGS_SECTION_ADMIN]({
        eventId: this.$route.params.eventId,
        trainingId: this.$route.params.trainingId,
      }),
    ]);
    await this[GET_ALL_TRAININGS_SECTION_ADMIN_WITH_LESSON]();

    this.listTrainer = this.trainingData?.trainers ?? [];

    this.lessonId = this.$route?.params?.lessonId;
    this.sectionId = this.$route?.params?.sectionId;
    this.initTrainingsSections(this.allPublicTrainingSectionData);

    this.trainingsSections.forEach((section) => {
      this.lessonList.push(...section.lessons);
    });

    if (this.lessonId) {
      this.lessonItem = this.lessonList.find(
        (ele) => ele.id === this.lessonId && ele.sectionId === this.sectionId,
      );
    }

    if (!this.sectionId || !this.lessonId || !this.lessonItem.authorize) {
      this.sectionId = this.trainingsSections[0].id;
      this.lessonId = this.trainingsSections[0].lessons[0].id;
      this.lessonItem = this.trainingsSections[0]?.lessons[0];

      this.$router?.replace({
        params: {
          sectionId: this.sectionId,
          lessonId: this.lessonId,
        },
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isInProgressCurrentLesson && from.params.lessonId !== to.params.lessonId) {
      this.showConfirmation = true;
      this.redirect = next;
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      from.params.lessonId === to.params.lessonId &&
      from.params.sectionId === to.params.sectionId
    ) {
      next(false);
    } else if (this.isInProgressCurrentLesson) {
      this.showConfirmation = true;
      this.redirect = next;
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
@import '@styles/core/variables';

.training-detail {
  height: calc(100vh - 4.3rem);
  width: 100vw;
  background-size: cover;
  height: 100%;
  min-height: calc(100vh - 4.3rem);
  background: #272626 fixed center;

  .header {
    font-size: $default-font-size;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    position: relative;

    &__back-text {
      display: none;
    }

    .mdi-arrow-left {
      position: absolute;
      left: 5px;
      transform: translate(0, -50%);
      top: 34%;
    }

    &--title {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem;

      .um {
        -webkit-background-clip: text !important;
        font-size: 32px;
        margin-top: -15px;
        -webkit-text-fill-color: transparent;
        background: transparent
          linear-gradient(270deg, #ffffff 0%, #ffffff 41%, #ffff2e 87%, #ffff2e 100%) 0% 0%
          no-repeat padding-box;
      }

      .process--data {
        color: #ffff2e;
      }
    }
  }

  &__body {
    padding-left: 0;

    &__section {
      padding: 0 0.5rem;
    }

    &__content {
      &__wrap {
        &__description {
          display: none;
        }
      }
    }

    // start - override class
    .v-tab {
      font-size: $default-font-size !important;
      font-weight: bold;
      text-transform: unset;
      padding: 0;
      margin-right: 1.5rem;
    }

    .v-tab:before {
      background: transparent;
    }

    .v-slide-group__wrapper {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .v-slide-group__content.v-tabs-bar__content {
      margin-bottom: 1rem;
    }

    .v-item-group {
      .v-expansion-panel {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        border-radius: 5px;
        box-shadow: 3px 3px 10px #00000029;
      }

      .v-expansion-panel-content {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .v-tab:not(.v-tab--active) {
      color: white !important;
    }

    .v-expansion-panel-header {
      padding: 0.75rem;

      &--active {
        box-shadow: 3px 3px 10px #00000029;
      }
    }

    .v-expansion-panel-header__icon {
      display: none;
    }

    .theme--light.v-tabs > .v-tabs-bar {
      background-color: transparent;
    }

    .v-tabs-items {
      background-color: transparent !important;
    }

    // end - override class
  }

  @include breakpoint(medium) {
    margin-left: -10vw;
    // start - override class
    .v-tab {
      text-transform: unset;
      font-weight: bold;
      font-size: $large-font-size !important;
      padding: 0;
      margin-right: 3rem;
    }
    // end - override class

    .header {
      font-size: $xx-large-font-size;
      text-align: start;

      &__back-text {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        width: max-content;

        .v-btn:before {
          background-color: transparent;
        }

        .mdi-chevron-left {
          margin-left: -11px;
          margin-right: -6px;
        }
      }

      &--title {
        justify-content: start;
      }

      .mdi-arrow-left {
        display: none;
      }
    }
    margin-left: -10vw;
    padding: 0 1rem;
    background-image: url('~@assets/backgrounds/training-detail1.png');
    background-size: cover;
    .training-detail {
      &__body {
        padding-left: 16px;

        &__section {
          padding: 0;
        }

        &__content {
          &__wrap {
            &__description {
              padding-left: 56px;
              padding-right: 56px;
              display: flex;
            }
          }

          .header {
            &--title {
              display: flex;
              gap: 2rem;
              padding: 0 0 0 40px;
              color: white;

              .process {
                font-size: $xx-large-font-size;
              }

              .um {
                font-size: 65px;
                margin-top: -2rem;
                margin-left: 2rem;
              }
            }
          }

          .content--title {
            font-size: $xx-large-font-size;
          }

          .formateur--section {
            width: 50%;

            .section {
              &__action {
                width: 115px;
                height: 90px;
                display: grid;

                &__text-video-call {
                  width: 115px;
                }
              }

              &__avatar {
                &__info {
                  width: 120px;
                }
              }
            }
          }

          .description--section {
            width: 50%;

            &__content {
              color: #c6c6c6;
            }
          }
        }
      }
    }
  }
}
</style>
