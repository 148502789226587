<template>
  <div class="trainings-section-item clickable">
    <v-expansion-panel-content class="grey lighten-2">
      <div class="">
        <div
          class="d-flex justify-space-between align-center trainings-section-item__wrap"
          v-bind:style="{ border: isLasted ? 'unset' : '' }"
          style="height: auto; min-height: 56px"
        >
          <div
            class="d-flex align-center trainings-section-item__wrap__left-item"
            @click="onSectionClick"
            :class="{
              'font-weight-bold': item.id === $route.params.lessonId,
              'trainings-section-item__wrap__left-item__clickable': this.item.authorize,
            }"
            style="overflow-wrap: anywhere"
          >
            <div class="trainings-section-item__wrap__left-item__wrap-image mr-2">
              <img
                v-if="isFinish"
                class="mr-2"
                src="~@assets/icons/svg/Cube_Check.svg"
                height="20"
              />
              <img v-else class="mr-2" src="~@assets/icons/svg/Cube_No.svg" height="20" />
            </div>
            {{ item.name }}
          </div>

          <!--right item start-->
          <button
            v-if="isShowResourceButton()"
            class="d-flex align-center p-2 rounded-lg trainings-section-item__wrap__right-item"
            @click="$emit('download-doc', item)"
          >
            <v-icon size="20" class="mr-2"> mdi-folder</v-icon>
            {{ $t('trainings.resource') }}
          </button>
          <button
            v-else-if="item.type === rightItemType.deposit"
            class="trainings-section-item__wrap__right-item d-flex align-center rounded-lg"
            @click="$emit('upload-doc', item)"
          >
            <v-icon size="20" class="mr-3"> mdi-upload</v-icon>
            Deposit
          </button>
          <div
            v-else-if="item.type === rightItemType.note"
            class="mt-1 gray-text trainings-section-item__wrap__right-item__note"
          >
            Note: {{ item.value }}%
          </div>
          <div
            v-else-if="item.type === rightItemType.process"
            class="mt-1 gray-text trainings-section-item__wrap__right-item__note"
          >
            {{ item.value }}
          </div>
          <a
            v-else-if="item.type === rightItemType.link"
            class="trainings-section-item__wrap__right-item__note pt-1"
            :href="item.content.websiteLink.content"
            target="_blank"
            style="text-decoration: none"
          >
            <v-icon size="30"> mdi-open-in-new</v-icon>
          </a>
          <!--right item end-->
        </div>
      </div>
    </v-expansion-panel-content>
  </div>
</template>
<script>
import { rightItemType } from '@/models/training/training-section-item.model';

export default {
  name: 'TrainingsSectionItem',
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
    },
    section: {
      type: Object,
      default: null,
    },
    isFinish: {
      type: Boolean,
      default: false,
    },
    isLasted: {
      type: Boolean,
      default: true,
    },
    authorize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rightItemType,
      modelDownloadDocs: false,
    };
  },
  computed: {},
  methods: {
    onSectionClick() {
      if (this.$route?.params?.lessonId !== this.item.id && this.authorize) {
        this.$router.replace({
          params: { sectionId: this.section.id, lessonId: this.item.id },
        });
      }
    },
    checkType(item) {
      if (item.type === rightItemType.reading) {
        if (item.type?.content?.reading?.resources?.length) return true;
        return false;
      }
      return false;
    },
    isShowResourceButton() {
      if (
        this.item.type === rightItemType.resources &&
        !!this.item.content.files?.accesFiles?.length &&
        this.authorize
      ) {
        return true;
      }
      if (
        this.item.type === rightItemType.video &&
        !!this.item.content.video?.ressources?.length &&
        this.authorize
      ) {
        return true;
      }
      if (
        this.item.type === rightItemType.reading &&
        !!this.item.content.reading?.ressources?.length &&
        this.authorize
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/styles/core/variables';

.trainings-section-item {
  .clickable {
    cursor: pointer;
  }

  .v-input__slot {
    margin-bottom: 0 !important;
  }

  .no-border {
    border: unset;
  }

  .v-input--checkbox {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .v-messages {
    display: none !important;
  }

  &__wrap {
    height: 3.5rem;
    border-bottom: 1px solid #707070;

    &__left-item {
      &__clickable {
        cursor: pointer;
      }

      &__wrap-image {
        width: 30px;
        display: flex;

        img {
          margin: auto;
        }
      }
    }

    &__right-item {
      height: 1rem;
      padding: 1.25rem 0.7rem;
      border: 1px solid #707070 !important;
      background: white;

      img {
        margin-right: 0.5rem;
      }

      cursor: pointer;

      &__note {
        color: #636363;
      }
    }
  }

  @include breakpoint(medium) {
    &__wrap {
      &__right-item {
        padding: 0.7rem;
      }
    }
  }
}
</style>
