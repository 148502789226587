<template>
  <div v-if="!trainingSectionStateAreLoading && !lessonIsLoading" class="trainings-lesson">
    <div class="trainings-lesson__content">
      <div
        class="d-flex align-center trainings-lesson__content__left"
        v-bind:class="{ 'chevron-bottom': type === VideoTypeEnum.READING.toString().toLowerCase() }"
      >
        <v-icon
          @click="onClickRedirect(false)"
          size="40"
          color="white"
          v-if="isShowPreLesson(lessonIndex)"
          :disabled="!navigationAllowed"
        >
          mdi-chevron-left
        </v-icon>
        <v-icon style="opacity: 0" size="40" color="white" v-else> mdi-chevron-left</v-icon>
      </div>
      <TrainingsLessonItem
        :trainingsSections="trainingsSections"
        :key="`trainings-lesson-item-key${lessonIndex}`"
        :item="lesson"
        :isLoading="!trainingSectionStateAreLoading && !lessonIsLoading"
        :navigationAllowed="navigationAllowed"
        @update-navigationAllowed="updateNavigationAllowed"
        @set-isInProgressCurrentLesson="setIsInProgressCurrentLesson"
      ></TrainingsLessonItem>
      <div
        class="d-flex align-center trainings-lesson__content__right"
        v-bind:class="{ 'chevron-bottom': type === VideoTypeEnum.READING.toString().toLowerCase() }"
      >
        <v-icon
          @click="onClickRedirect(true)"
          size="40"
          color="white"
          v-if="isShowNextLesson(lessonIndex)"
          :disabled="!navigationAllowed || !lesson.isDone"
        >
          mdi-chevron-right
        </v-icon>
        <v-icon style="opacity: 0" size="40" color="white" v-else> mdi-chevron-right</v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import { mapGetters } from 'vuex';
import { TRAINING_SECTION_ADMIN_MODULE } from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import { TRAINING_ADMIN_MODULE } from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import TrainingsLessonItem from './trainings-lesson-item/TrainingsLessonItem.vue';

export default {
  name: 'TrainingsLesson',
  components: {
    TrainingsLessonItem,
  },
  props: {
    navigationAllowed: Boolean,
    trainingsSections: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    idLesson: '',
    idSection: '',
    lessonIsLoading: true,
    isLastSection: false,
    lessonIndex: 0,
    VideoTypeEnum,
    type: null,
    lesson: null,
    lessonList: [],
  }),
  computed: {
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, ['trainingSectionStateAreLoading']),
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingProgress', 'trainingProgressLoading']),
  },
  methods: {
    updateNavigationAllowed(allowed) {
      this.$emit('update-navigationAllowed', allowed);
    },
    setIsInProgressCurrentLesson(boolean) {
      this.$emit('set-isInProgressCurrentLesson', boolean);
    },
    onClickRedirect(isNext) {
      const curIndex = this.lessonList.findIndex((ele) => ele.id === this.idLesson) ?? 0;
      if (!curIndex && curIndex !== 0) return;
      if (!isNext) {
        if (this.lessonIndex - 1 < 0) {
          const curSectionIndex = this.trainingsSections.findIndex(
            (ele) => ele.id === this.$route.params.sectionId,
          );
          if (curSectionIndex === 0) return;
          if (
            this.trainingsSections[curSectionIndex - 1] &&
            this.trainingsSections[curSectionIndex - 1]?.lessons?.length
          ) {
            this.$router.replace({
              params: {
                sectionId: this.trainingsSections[curSectionIndex - 1].id,
                lessonId: this.trainingsSections[curSectionIndex - 1]?.lessons.at(-1).id,
                savePosition: true,
                position: { x: 0, y: document.documentElement.scrollTop },
              },
            });
            return;
          }
        }
        this.lessonIndex -= 1;
        this.$router.replace({
          params: {
            sectionId: this.$route?.params?.sectionId,
            lessonId: this.lessonList[this.lessonIndex]?.id,
            savePosition: true,
            position: { x: 0, y: document.documentElement.scrollTop },
          },
        });
      } else {
        this.lessonIndex += 1;
        if (this.lessonIndex === this.lessonList.length) {
          const curSectionIndex = this.trainingsSections.findIndex(
            (ele) => ele.id === this.$route.params.sectionId,
          );
          if (
            this.trainingsSections[curSectionIndex + 1] &&
            this.trainingsSections[curSectionIndex + 1]?.lessons?.length
          ) {
            this.$router.replace({
              params: {
                sectionId: this.trainingsSections[curSectionIndex + 1].id,
                lessonId: this.trainingsSections[curSectionIndex + 1]?.lessons[0].id,
                savePosition: true,
                position: { x: 0, y: document.documentElement.scrollTop },
              },
            });
          }
        } else {
          this.$router.push({
            replace: false,
            params: {
              sectionId: this.$route?.params?.sectionId,
              lessonId: this.lessonList[this.lessonIndex]?.id,
              savePosition: true,
              position: { x: 0, y: document.documentElement.scrollTop },
            },
          });
        }
      }
    },
    initLesson() {
      this.lessonIsLoading = true;
      const section = this.trainingsSections.find((ele) => ele.id === this.$route.params.sectionId);
      if (!section?.lessons?.length) {
        this.lessonIsLoading = false;
        return;
      }
      this.lessonList = section?.lessons ?? [];
      this.lessonIndex = this.lessonList.findIndex((ele) => ele.id === this.$route.params.lessonId);
      this.type = this.lessonList[this.lessonIndex]?.type;
      this.lesson = this.lessonList[this.lessonIndex];
      this.lessonIsLoading = false;
    },
    isShowPreLesson(currentIndex) {
      if (!this.navigationAllowed) return false;
      if (currentIndex > 0) return true;
      const curSectionIndex = this.trainingsSections.findIndex(
        (ele) => ele.id === this.$route.params.sectionId,
      );
      if (curSectionIndex === 0) return false;
      if (
        this.trainingsSections[curSectionIndex - 1] &&
        this.trainingsSections[curSectionIndex - 1]?.lessons?.length
      ) {
        return true;
      }
      return false;
    },
    isShowNextLesson(currentIndex) {
      if (!this.navigationAllowed) return false;
      if (currentIndex < this.lessonList.length - 1) {
        return true;
      }
      const curSectionIndex = this.trainingsSections.findIndex(
        (ele) => ele.id === this.$route.params.sectionId,
      );

      if (curSectionIndex === -1) {
        return false;
      }
      if (
        this.trainingsSections[curSectionIndex + 1] &&
        this.trainingsSections[curSectionIndex + 1]?.lessons?.length
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route: {
      handler() {
        this.initLesson();
      },
      deep: true,
      immediate: true,
    },
    trainingsSections() {
      this.initLesson();
    },
  },
};
</script>

<style lang="scss">
@import '@styles/core/variables';

.trainings-lesson {
  &-item--reading--content img {
    cursor: default !important;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;

    &__left,
    &__right {
      position: absolute;
      transform: translate(0, -50%);
      z-index: 3;
      top: 50%;
    }

    &__left {
      left: 0.5rem;

      .mdi-chevron-left {
        height: 50px;
        width: 40px;
        background: #272626;
        border-radius: 5px;
      }
    }

    &__right {
      right: 0.5rem;

      .mdi-chevron-right {
        height: 50px;
        width: 40px;
        background: #272626;
        border-radius: 5px;
      }
    }

    .theme--light.v-icon:focus::after {
      display: none;
    }

    img {
      cursor: pointer;
    }
  }

  @include breakpoint(medium) {
    .mdi-chevron-right {
      font-size: 72px !important;
      background: none;
    }
    .mdi-chevron-left {
      font-size: 72px !important;
      background: none;
    }
    &__content {
      &__left,
      &__right {
        position: relative;
        transform: unset;
        background: transparent;
        margin-left: 5px;
      }

      &__left {
        margin-right: 5px;
        left: 0;
      }

      &__right {
        margin-left: 5px;
        right: 0;
      }
    }
  }
}
</style>
