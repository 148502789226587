<template>
  <v-dialog v-model="open" width="500">
    <v-card>
      <v-card-title class="d-flex justify-center popup-header pa-0">
        <v-icon size="40"> mdi-upload </v-icon>
        <v-icon @click="close" color="primary" class="close-btn">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="grey lighten-2 d-flex align-center popup-body">
        <div class="upload-section">
          <span class="upload-label">{{ $tc('trainings.uploadDocument') }}</span>
          <v-file-input :prepend-icon="null" hide-details />
        </div>

        <v-btn color="primary ml-auto" width="150">
          {{ $t('globals.upload') }}

          <v-icon right dark> mdi-upload </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UploadDocuments',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  data: () => ({}),
  computed: {
    open: {
      get() {
        return this.dialog;
      },
      set() {
        return this.close();
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

.popup-header {
  height: 64px;
}

.upload-label {
  font-weight: bold;
  font-size: 1rem;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
}

::v-deep .v-text-field {
  padding-top: 0px;
}

.popup-body {
  height: 100px;
  padding-bottom: 0px !important;
}

.upload-section {
  width: 200px;
}
</style>
